import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.png"
import {Row, Col, Container} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedin,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons"
import {
  faEnvelope,
  faMap,
} from "@fortawesome/free-regular-svg-icons"
import {
  faInbox,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"

const Footer = () => {

  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">
      <Container>
        <Row>
          <Col lg={4} xs={12}>
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p>Die Profis für Software in der Versandlogistik und im Warehouse Management.</p>

                  <a href="https://facebook.com/logentis">
                    <FontAwesomeIcon icon={faFacebook} aria-label={"Facebook"} size={"2x"} className={"m-2"} />
                  </a>
                  <a href="https://www.linkedin.com/company/logentis-gmbh">
                  <FontAwesomeIcon icon={faLinkedin} aria-label={"LinkedIn"} size={"2x"} className={"m-2"} /></a>
                  <a href="https://twitter.com/logentis">
                    <FontAwesomeIcon icon={faTwitter} aria-label={"Twitter"} size={"2x"} className={"m-2"}/>
                  </a>
            </div>
          </Col>

          <Col lg={2} xs={6}>
            <div className="single-footer-widget">
              <h3>Entdecke</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/versandsoftware/">
                    NETVERSYS
                  </Link>
                </li>
                <li>
                  <Link to="/lagerverwaltung/">
                    NETSTORSYS
                  </Link>
                </li>
                <li>
                  <Link to="/jobs/">
                    Jobs
                  </Link>
                </li>
                <li>
                  <Link to="/blog/">
                    Unser Blog
                  </Link>
                </li>
                <li>
                  <Link to="/kontakt/">
                    Kontakt
                  </Link>
                </li>
              </ul>
            </div>
          </Col>

          <Col lg={2} xs={6}>
            <div className="single-footer-widget">
              <h3>Ressourcen</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/blog/">
                    Blogarchiv
                  </Link>
                </li>
                <li>
                  <Link to="/kurznewsarchiv/">
                    Newsarchiv
                  </Link>
                </li>
                <li>
                  <Link to="/firmenhistorie/">
                    Firmenhistorie
                  </Link>
                </li>
                <li>
                  <Link to="/kundenprojekte/">
                    Kundenprojekte
                  </Link>
                </li>
                <li>
                  <Link to="/lexikon/">
                    Lexikon
                  </Link>
                </li>
              </ul>
            </div>
          </Col>

          <Col lg={4} xs={12}>
            <div className="single-footer-widget">
              <h3>Adresse</h3>

              <ul className="fa-ul">
                <li className={"mb-2"}>
                  <FontAwesomeIcon icon={faMap} aria-label={"Map"}  listItem className={"mr-3 mt-1"}/>
                  Franz-Lenz Str. 4, 49084 Osnabrück, Deutschland
                </li>
                <li className={"mb-2"}>
                  <FontAwesomeIcon icon={faPhone} aria-label={"Phone"}  listItem className={"mr-3  mt-1"}/>
                  <a href="tel:+495415805870">+49 (0)541 580587 0</a>
                </li>
                <li className={"mb-2"}>
                  <FontAwesomeIcon icon={faEnvelope} aria-label={"Enveloper"} listItem className={"mr-3 mt-1"}/>
                  <a href="mailto:info@logentis.de">info@logentis.de</a>
                </li>
                <li className={"mb-2"}>
                  <FontAwesomeIcon icon={faInbox} aria-label={"Inbox"} listItem className={"mr-3 mt-1"}/>
                  +49 (0)541 580587 99
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>Copyright @{currentYear} <strong>LOGENTIS GmbH</strong>. All rights reserved.
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/datenschutz/">
                    Datenschutz
                  </Link>
                </li>
                <li>
                  <Link to="/impressum/">
                    Impressum
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
